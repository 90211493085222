/**
 * Stores build information. The string literals are replaced at compile time by `set_version.ts`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2024-09-17",
    versionChannel: "nightly",
    buildDate: "2024-09-17T00:05:11.715Z",
    commitHash: "4fd9dc45c5c54548941e6263d5c5f271f388ed33",
};
