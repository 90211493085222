/**
 * Stores build information. The string literals are replaced at compile time by `set_version.ts`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2024-09-19",
    versionChannel: "nightly",
    buildDate: "2024-09-19T00:05:57.638Z",
    commitHash: "0729e796597e05e43d29601549a483f7709a1cc9",
};
